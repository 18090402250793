<template>
  <span>
    <v-menu
      v-model="isAction"
      left
      offset-y
      :close-on-content-click="false"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="primary"
          dark
          title="Assigned / Unassigned of Business"
          v-bind="attrs"
          style="min-width: 30px; max-width: 45px"
          v-on="on"
        >
          <!-- small
              style="height: 40px" -->
          <v-icon>mdi-office-building-cog</v-icon>
        </v-btn>
      </template>
      <v-list
        v-if="isAction"
        style="width: 300px"
      >
        <v-radio-group v-model="actionFilter.value">
          <v-list-item
            v-for="(act, i) in actions"
            :key="i"
            two-line
          >
            <v-list-item-content>
              <v-list-item-title>
                <v-radio
                  :value="act.key"
                  :label="$t(act.label)"
                  hide-details
                  :ripple="false"
                />
                <!-- @change="() => (actionFilter[act.key] = null)" -->
              </v-list-item-title>
              <v-list-item-subtitle
                v-if="actionFilter.value == 'assign' && i == 0"
              >
                <div>
                  <v-select
                    v-model="actionFilter.product"
                    dense
                    :items="['oem', 'fleet']"
                    item-text="name"
                    item-value="id"
                  />
                </div>
                <div>
                  <v-select
                    v-model="actionFilter.tenant_ids"
                    dense
                    :items="tenantByProduct"
                    item-value="id"
                    item-text="name"
                    multiple
                    clearable
                  />
                </div>
              </v-list-item-subtitle>
              <!--  -->
              <v-list-item-subtitle
                v-if="actionFilter.value == 'unassign' && i == 1"
              >
                <div>
                  <v-select
                    v-model="actionFilter.product"
                    dense
                    :items="['oem', 'fleet']"
                    item-text="name"
                    item-value="id"
                  />
                </div>
                <div>
                  <v-select
                    v-model="actionFilter.tenant_ids"
                    dense
                    :items="tenantByProduct"
                    item-value="id"
                    item-text="name"
                    multiple
                    clearable
                  />
                </div>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-radio-group>
        <v-divider />
        <v-list-item-action class="d-flex justify-end mr-2">
          <v-btn
            color="primary"
            small
            :disabled="!actionFilter.tenant_ids ? true : false"
            @click="onActionApply"
          >
            {{ $t("apply") }}
          </v-btn>
        </v-list-item-action>
      </v-list>
    </v-menu>
  </span>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  props: {
    type: {
      type: String,
      default() {
        return "";
      },
    },
  },
  data() {
    return {
      isAction: false,
      actionFilter: {},
      actions: [
        {
          value: false,
          key: "assign",
          label: "assigned",
          action: "assign",
        },
        {
          value: false,
          key: "unassign",
          label: "unassigned",
          action: "unassign",
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      tenantsList: "tenantsManagement/getTenantOptions",
      tenantOEM: "tenantsManagement/getTenantsOEM",
      tenantFLEET: "tenantsManagement/getTenantsFLEET",
    }),
    tenantByProduct() {
      if (this.actionFilter.product) {
        if (this.actionFilter.product == "oem") {
          return this.tenantOEM;
        } else {
          return this.tenantFLEET;
        }
      } else {
        return [];
      }
    },
  },
  methods: {
    onActionApply() {
      if (this.actionFilter.tenant_ids.length > 0) {
        this.$emit("apply", this.actionFilter);
        this.isAction = false;
        this.actionFilter = {};
      }
      //    else {
      //     ("no tenat select");
      //   }
    },
    //
    // SetBulkData() {
    //   ("set bulk for", this.type);
    // },
    //
  },
};
</script>
<style lang="sass" scoped>

// :deep .v-menu__content
:deep .v-radio
  label
    color: black !important

:deep .v-menu__content
  background: red !important
</style>
